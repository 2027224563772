<template>
  <v-dialog v-model="dialog" max-width="900px">
    <v-form ref="form">
      <v-card>
        <v-card-title>
          <span class="headline">Side Product</span>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-sheet :color="`grey darken-2`" class="pa-3" v-if="!isLoaded">
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="900"
                  type="card"
                ></v-skeleton-loader>
              </v-sheet>
              <v-container grid-list-xl v-if="isLoaded">
                <v-layout wrap justify-space-between>
                  <v-flex xs12 md12>
                    <v-alert :value="alert" type="success"
                      >Başarıyla kaldedildi.</v-alert
                    >
                  </v-flex>
                  <v-flex xs4 md4>
                    <v-combobox
                      v-model="sideProductTypeSelect"
                      :items="$store.state.homes.enumTypes.sideProductType"
                      item-value="value"
                      @change="changeSideProductType"
                      item-text="id"
                      hide-details
                      label="Product Type"
                    ></v-combobox>
                  </v-flex>
                  <v-flex xs4 md4>
                    <v-combobox
                      :items="$store.state.products.ids"
                      v-model="product.productId"
                      hide-details
                      label="productId"
                    ></v-combobox>
                  </v-flex>
                  <v-flex xs4 md4>
                    <v-combobox
                      :items="$store.state.dynamicOffers.ids"
                      v-model="product.offerId"
                      hide-details
                      label="offerId"
                    ></v-combobox>
                  </v-flex>
                  <v-flex xs3 md3>
                    <v-text-field
                      v-model="product.purchaseCount"
                      label="Purchase Count"
                      :counter="max"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 md3 v-if="winTypeSelect.value != 5">
                    <v-text-field
                      v-model="product.minSpend"
                      label="Min Spend"
                      :counter="max"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 md3 v-if="winTypeSelect.value != 5">
                    <v-text-field
                      v-model="product.maxSpend"
                      label="Max Spend"
                      type="number"
                      :counter="max"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex
                    xs12
                    md12
                    v-if="
                      sideProductTypeSelect.value != 5 &&
                      sideProductTypeSelect.value != 0
                    "
                  >
                    <v-text-field
                      v-model="product.model"
                      label="Model"
                      :counter="max"
                      type="number"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 md3 v-if="winTypeSelect.value == 5">
                    <v-text-field
                      v-model="product.minStake"
                      label="Min Stake"
                      :counter="max"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 md3 v-if="winTypeSelect.value == 5">
                    <v-text-field
                      v-model="product.maxStake"
                      label="Max Stake"
                      :counter="max"
                      required
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs3 md3>
                    <v-combobox
                      v-if="sideProductTypeSelect.value == 0"
                      :items="$store.state.homes.enumTypes.albumPackWinType"
                      v-model="winTypeSelect"
                      item-value="value"
                      item-text="id"
                      hide-details
                      label="Win Type"
                    ></v-combobox>
                  </v-flex>

                  <v-flex xs12 md12 v-if="sideProductTypeSelect.value == 0">
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          v-model="albumPack.cardCount"
                          label="cardCount"
                          :counter="max"
                          placeholder="100"
                          required
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="albumPack.minStar"
                          label="minStar"
                          :counter="max"
                          :rules="getMinStart()"
                          placeholder="100"
                          required
                          type="number"
                          @keydown="validateField()"
                          @chnage="validateField()"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field
                          v-model="albumPack.maxStar"
                          label="maxStar"
                          :rules="getMaxStart()"
                          @keydown="validateField()"
                          @chnage="validateField()"
                          :counter="max"
                          placeholder="100"
                          required
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-combobox
                          :items="albumItemType"
                          v-model="albumPack.type"
                          item-value="key"
                          item-text="value"
                          hide-details
                          label="Type"
                        ></v-combobox>
                      </v-col>

                      <v-col cols="2">
                        <v-btn color="success" @click="addAlbum">Add</v-btn>
                      </v-col>
                    </v-row>
                    <v-data-table
                      :items="albumPacks"
                      :headers="headers"
                      :items-per-page="50"
                      class="elevation-1"
                    >
                      <template v-slot:item.cardCount="{ item }">
                        <v-text-field
                          v-model="item.cardCount"
                          label="cardCount"
                          :counter="max"
                          placeholder="100"
                          required
                          type="number"
                        ></v-text-field>
                      </template>
                      <template v-slot:item.minStar="{ item }">
                        <v-text-field
                          v-model="item.minStar"
                          label="minStar"
                          :counter="max"
                          placeholder="100"
                          required
                          type="number"
                        ></v-text-field>
                      </template>
                      <template v-slot:item.maxStar="{ item }">
                        <v-text-field
                          v-model="item.maxStar"
                          label="maxStar"
                          :counter="max"
                          placeholder="100"
                          required
                          type="number"
                        ></v-text-field>
                      </template>
                      <template v-slot:item.type="{ item }">
                        <v-combobox
                          :items="albumItemType"
                          v-model="item.type"
                          hide-details
                          item-value="key"
                          item-text="value"
                          label="Type"
                        ></v-combobox>
                      </template>
                    </v-data-table>
                  </v-flex>

                  <v-flex xs12 md12 v-if="sideProductTypeSelect.value == 5">
                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          v-model="boost.second"
                          label="Second"
                          :counter="max"
                          placeholder="100"
                          required
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-combobox
                          :items="$store.state.homes.enumTypes.boostType"
                          v-model="boost.type"
                          item-value="value"
                          item-text="id"
                          hide-details
                          label="Type"
                        ></v-combobox>
                      </v-col>
                    </v-row>
                  </v-flex>
                  <v-btn color="success" @click="save">Save</v-btn>
                </v-layout>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import albumItemType from "@/lib/albumItemType";
export default {
  data: () => ({
    dialog: false,
    product: {},
    albumPack: { minStar: 0, maxStar: 0, type: { key: 0, value: "Normal" } },
    albumPacks: [],
    isLoaded: false,
    albumItemType: albumItemType,
    alert: false,
    max: 120,
    rules: {},
    boost: {
      type: { value: 0, id: "Xp" },
    },
    sideProductTypeSelect: { value: 0, id: "AlbumPack" },
    winTypeSelect: { id: "Purchase", value: 0 },
    model: null,
    productId: null,
    offerId: null,
    headers: [
      { text: "CardCount", value: "cardCount" },
      { text: "MinStar", value: "minStar" },
      { text: "MaxStar", value: "maxStar" },
      { text: "Type", value: "type" },
    ],
  }),

  methods: {
    getMinStart() {
      return [
        (v) => v >= 1 || "Loan should be above 1",
        (v) => v <= 5 || "Max should not be above 5",
        (v) =>
          v <= this.albumPack.maxStar || "MinStar cannot be less than MaxStar",
      ];
    },
    getMaxStart() {
      return [
        (v) => v >= 1 || "Loan should be above 1",
        (v) =>
          v >= this.albumPack.minStar || "Maxstart cannot be less than MinStar",
        (v) => v <= 5 || "Max should not be above 5",
      ];
    },
    validateField() {
      setTimeout(this.$refs.form.validate(), 1);
    },
    addAlbum() {
      // eslint-disable-next-line no-console
      console.log(this.$refs.form.validate());
      this.albumPacks.push({
        cardCount: this.albumPack.cardCount,
        minStar: this.albumPack.minStar,
        maxStar: this.albumPack.maxStar,
        type: this.albumPack.type,
      });
    },
    editItem(item) {
      this.albumPack = item;
    },
    async save() {
      this.alert = false;
      this.product.type = this.sideProductTypeSelect.value;
      this.product.minSpend = parseInt(this.product.minSpend || 0);
      this.product.maxSpend = parseInt(this.product.maxSpend || 0);
      this.product.purchaseCount = parseInt(this.product.purchaseCount || 0);

      switch (this.product.type) {
        case 0:
          this.product.model = {};
          this.product.model.type = this.winTypeSelect.value;
          this.product.model.packs = [];
          this.albumPacks.forEach((element) => {
            this.product.model.packs.push([
              parseInt(element.cardCount || 0),
              parseInt(element.minStar || 0),
              parseInt(element.maxStar || 0),
              parseInt(element.type.key || 0),
            ]);
          });
          break;
        case 5:
          this.product.model = {};
          this.product.model.type = this.boost.type.value;
          this.product.model.second = parseInt(this.boost.second);
          break;
        default:
          this.product.model = parseFloat(this.product.model || 0);
          break;
      }

      // eslint-disable-next-line no-console
      console.log(this.product, this.product.model, this.product.model.packs);
      await this.$store.dispatch("sideProducts/createOrUpdate", this.product);

      await this.$store.dispatch("sideProducts/getbyProductOrOfferId", {
        id: this.productId,
        offerId: this.offerId,
      });
      this.product = { productId: this.productId };
      this.alert = true;
      this.dialog = false;
    },
    changeSideProductType() {
      this.product.model = [];
    },
    async initialize(id, productId, offerId) {
      this.productId = productId;
      this.offerId = offerId;
      this.isLoaded = false;
      this.alert = false;
      this.dialog = true;
      this.albumPacks = [];

      this.product = { productId: productId, offerId: offerId, model: [] };
      await this.$store.dispatch("products/getIds");
      await this.$store.dispatch("dynamicOffers/getIds");
      if (id) {
        await this.$store.dispatch("sideProducts/get", id);
        this.product = this.$store.state.sideProducts.detail;
        this.sideProductTypeSelect =
          this.$store.state.homes.enumTypes.sideProductType.find(
            (m) => m.value == this.product.type
          );

        this.winTypeSelect =
          this.$store.state.homes.enumTypes.albumPackWinType.find(
            (m) => m.value == this.product.model.type
          );

        switch (this.product.type) {
          case 0:
            this.product.model.packs.forEach((element) => {
              this.albumPacks.push({
                cardCount: element[0],
                minStar: element[1],
                maxStar: element[2],
                type: this.albumItemType.find((m) => m.key == element[3]),
              });
            });
            break;
          case 5:
            this.boost = this.product.model;
            this.product.model.type =
              this.$store.state.homes.enumTypes.boostType.find(
                (m) => m.value == this.product.model.type
              );
            break;
          default:
            break;
        }
      }
      this.isLoaded = true;
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
  async created() {},
};
</script>
